import Image from 'next/image'
import React from 'react'
import styles from './SiteCountryPicker.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useCountry } from '@/utils/hooks/useCountry'
import { useCustomer } from '@/utils/hooks/useCustomer'
import Modal from '@/components/common/Modal/Modal'
import Button from '@/components/buttons/Button/Button'

type Props = {
  label?: string | null
  countryAsLabel?: boolean
}

type CountryItemProps = {
  countryCode: string,
  currencyCode: string,
  text: string,
  onClick: (name: string) => void
  selected: boolean
}

const CountryItem = (props: CountryItemProps) => {
  const onClickHandler = () => {
    props.onClick(props.countryCode)
  }

  return (
    <div className={classNames(styles.country, { [styles.selected]: props.selected })} onClick={onClickHandler}>
      <Image src={`/countries/${props.countryCode.toLowerCase()}.png`} alt={props.countryCode} width="20" height="20" /> {props.text}
    </div>
  )
}

const SiteCountryPicker = (props: Props) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedCountry, setSelectedCountry] = React.useState<string>('')
  const { countries, countryCode, changeCountry } = useCountry()
  const { customer } = useCustomer()

  const onClickHandler = (countryCode: string) => {
    setSelectedCountry(countryCode)
  }

  const onConfirmCountry = () => {
    changeCountry(selectedCountry, null)
    onClose()
  }

  const onOpen = () => {
    if (customer)
      return

    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
    setSelectedCountry('')
  }

  const label = props.countryAsLabel ? countries?.find(c => c.countryCode === countryCode)?.name : props.label
  const hasLabel = label && label.length > 0

  return (
    <div className={styles.component}>
      <div className={classNames(styles.current, { [styles.disabled]: customer, [styles.hasLabel]: hasLabel })} onClick={onOpen}>
        <div>{label}</div> <Image src={`/countries/${countryCode.toLowerCase()}.png`} alt={countryCode} width="20" height="15" />
      </div>

      {isOpen && (
        <Modal onClose={onClose} title={t('country')} clickOutsideToClose={true}>
          <div className={styles.countryWindow}>
            <p>{t('selectCountryInfo')}</p>
            <div className={styles.selectlist}>
              {countries?.map((country) => (
                <CountryItem
                  key={country.countryCode}
                  countryCode={country.countryCode}
                  currencyCode={country.currencyCode}
                  text={country.name}
                  onClick={onClickHandler}
                  selected={selectedCountry === country.countryCode}
                />
              ))}
            </div>
          </div>
          <div className={styles.button}>
            <Button
              onClick={onConfirmCountry}
              large={true}
              rounded={true}
              label={t('update')}
              width='100%'
              disabled={!selectedCountry}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default SiteCountryPicker