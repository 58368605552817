import { useGetCustomerQuery } from '@/api/customer'
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'

interface Menu {
  url: string,
  name: string,
  label: string,
}

const useAccountMenu = () => {
  const [menu, setMenu] = React.useState<Menu[]>([])
  const { t } = useTranslation('common')
  const { data: customer } = useGetCustomerQuery()


  useEffect(() => {
    const items = [
      { url: '/account', name: 'info', label: t('userDetails') || '' },
      { url: '/account/addresses', name: 'addresses', label: t('addresses') || '' },
      { url: '/account/orders', name: 'orders', label: t('orderHistory') || '' },
      { url: '/account/receipts', name: 'receipts', label: t('receipts') || '' },
      { url: '/account/invoices', name: 'invoices', label: t('invoices') || '' },
    ].filter((item) => item.name !== 'receipts' || customer?.hasReceipts)

    setMenu(items)
  }, [customer?.hasReceipts, t])

  return menu
}

export default useAccountMenu

