import { LoginViewModel } from "@/types/api"
import { baseApi } from "./baseApi"

const loginApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<number, LoginViewModel>({
      query: (payload) => ({
        url: `login`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Customer', 'Cart', 'Search']
    }),
    logout: builder.mutation<null, void>({
      query: () => ({
        url: `login`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Customer']
    })
  })
})

export const { useLoginMutation, useLogoutMutation } = loginApi
