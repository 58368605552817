import { CustomerEditModel, CustomerModel } from "@/types/api"
import { baseApi } from "./baseApi"

const customerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query<CustomerModel, void>({
      query: () => ({
        url: `customer`,
        method: "GET",
      }),
      providesTags: ['Customer']
    }),
    editCustomer: builder.mutation<void, CustomerEditModel>({
      query: (body) => ({
        url: `customer`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ['Customer']
    }),
  }),
})

export const {
  useGetCustomerQuery,
  useEditCustomerMutation
} = customerApi