import styles from './BurgerButton.module.scss'
import React from 'react'
import classNames from 'classnames'

type Props = {
  onClick: () => void
  isOpen: boolean
}

const BurgerButton = (props: Props) => {
  const classes = classNames(styles.component,
    {
      [styles.open]: props.isOpen
    })

  return (
    <div className={classes} onClick={props.onClick}>
      <div className={styles.icon}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default BurgerButton