import { useLogoutMutation } from "@/api/login"
import { useRouter } from "next/router"

const useLogout = () => {
  const router = useRouter()
  const [logout] = useLogoutMutation()

  const handleLogout = async () => {
    await logout()
    router.push('/')
    router.reload()
  }

  return handleLogout
}

export default useLogout
