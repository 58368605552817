import styles from './CartWidget.module.scss'
import Button from '@/components/buttons/Button/Button'
import React, { useTransition } from 'react'
import ShoppingCartIcon from '@/assets/svg/cart.svg'
import { useGetCartQuery } from '@/api/cart'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCountry } from '@/utils/hooks/useCountry'

type Props = {}

const CartWidget = (props: Props) => {
  const { t } = useTranslation('common')
  const { countryCode } = useCountry()
  const { data, isFetching } = useGetCartQuery(countryCode)
  const router = useRouter()

  const onClick = () => {
    router.push('/cart')
  }

  let totalItems: string | number = '-'

  if (data) {
    totalItems = data?.rows.reduce((acc, item) => acc + item.quantity, 0)
  }

  return (
    <div className={styles.component}>
      <Button
        onClick={onClick}
        label={`${totalItems} ${t('pieceABBR')}`}
        icon={ShoppingCartIcon}
        iconPosition={'right'}
      />
    </div>
  )
}

export default CartWidget