/**
 * Google tag manager
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#implementation
 */

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

type WindowWithMethods = Window & {
  dataLayer: any
}

declare const window: WindowWithMethods

export type GoogleAnalyticsItem = {
  item_id: number
  item_name: string
  quantity?: number
  price?: number
}

export const pageView = (url: string) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  })
}

export const addToCart = (articleId: number, name: string, quantity: number, price: number, currencyCode: string) => {
  if (window.dataLayer === undefined)
    return

  const item: GoogleAnalyticsItem = {
    item_id: articleId,
    item_name: name,
    quantity,
    price
  }

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currencyCode: currencyCode,
      value: price,
      items: [item]
    }
  })
}

export const removeFromCart = (articleId: number, name: string, quantity: number, price: number) => {
  if (window.dataLayer === undefined)
    return

  const item: GoogleAnalyticsItem = {
    item_id: articleId,
    item_name: name,
    quantity,
    price
  }

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [item]
    }
  })
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_cart-gtm
 */
export const viewCart = (price: number, currencyCode: string, rows: GoogleAnalyticsItem[]) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: rows
    }
  })
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_payment_info-gtm
 */
export const addPaymentInfo = (paymentType: string, price: number, currencyCode: string, rows: GoogleAnalyticsItem[]) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      payment_type: paymentType,
      currency: currencyCode,
      value: price,
      items: rows
    }
  })
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_shipping_info-gtm
 */
export const addShippingInfo = (shippingTier: string, price: number, currencyCode: string, rows: GoogleAnalyticsItem[]) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      shipping_tier: shippingTier,
      currency: currencyCode,
      value: price,
      items: rows
    }
  })
}

export const beginCheckout = (price: number, currencyCode: string, rows: GoogleAnalyticsItem[]) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: rows
    }
  })
}

export const purchase = (price: number, currencyCode: string, rows: GoogleAnalyticsItem[]) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: rows
    }
  })
}

export const viewItem = (articleId: number, name: string, price: number, currencyCode: string) => {
  if (window.dataLayer === undefined)
    return

  const item: GoogleAnalyticsItem = {
    item_id: articleId,
    item_name: name,
    price
  }

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: [item]
    }
  })
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_an_item_from_a_list
 * @param listId 
 * @param listName 
 * @param items 
 */
export const viewItemList = (listId: string, listName: string, items: GoogleAnalyticsItem[]) => {
  if(window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: listId,
      item_list_name: listName,
      items
    }
  })
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_item-gtm
 */
export const selectItem = (listId: string, listName: string, item: GoogleAnalyticsItem) => {
  if(window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      item_list_id: listId,
      item_list_name: listName,
      items: [item]
    }
  })
}

export const viewPromotion = () => {
  // Implement the logic to view details of a promotion
}

export const generateLead = () => {
  // Implement the logic to generate a lead (potential customer)
}

export const refund = () => {
  // Implement the logic to process a refund
}

export const selectPromotion = () => {
  // Implement the logic to select a promotion or discount
}

export const login = (userId: string) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    'event': 'login',
    'user_id': userId
  })
}

export const signUp = (userId: string) => {
  if (window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    'event': 'sign_up',
    'user_id': userId
  })
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#search
 */
export const search = (keyword: string) => {
  if(window.dataLayer === undefined)
    return

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    'event': 'search',
    'search_term': keyword
  })
}
