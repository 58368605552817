import styles from './MobileHeader.module.scss'
import React from 'react'
import Link from 'next/link'
import logo from '@/assets/images/logo.png'
import Image from 'next/image'
import useMenu from '@/utils/hooks/useMenu'
import classNames from 'classnames'
import Button from '@/components/buttons/Button/Button'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import BurgerButton from '@/components/buttons/BurgerButton/BurgerButton'
import { useGetCustomerQuery } from '@/api/customer'
import Account from '@/components/common/Account/Account'
import useAccountMenu from '@/utils/hooks/useAccountMenu'
import PhoneIcon from '@/assets/svg/phone.svg'
import useLogout from '@/utils/hooks/useLogout'
import SiteCountryPicker from '@/features/app/SiteCountryPicker/SiteCountryPicker'

type Props = {}

const MobileHeader = (props: Props) => {
  const { t, i18n } = useTranslation('common')
  const [isOpen, setIsOpen] = React.useState(false)
  const menuItems = useMenu()
  const accountMenuItems = useAccountMenu()
  const router = useRouter()
  const { data: customer } = useGetCustomerQuery()
  const logout = useLogout()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const classes = classNames(styles.component, {
    [styles.open]: isOpen,
  })

  const onClickLogin = () => {
    router.push('/login')
    toggle()
  }

  return (
    <div className={classes}>
      <header>
        <SiteCountryPicker />
        <Link href="/"><Image src={logo} height={19} alt="Statusfälgar" /></Link>
        <a className={styles.phoneIcon} href="tel:+46852705264"><PhoneIcon /></a>
        <BurgerButton
          isOpen={isOpen}
          onClick={toggle}
        />
      </header>
      <div className={styles.menuContent}>
        <nav>
          <ol>
            {menuItems.map((item, index) => (
              <Link key={index} href={item.url} onClick={() => toggle()}>
                <li>{item.name}</li>
              </Link>
            ))}
          </ol>
        </nav>

        {customer && customer.id && (
          <div>
            <Link href="/account">
              <Account />
            </Link>
            <nav>
              <ol>
                {accountMenuItems.map((item, index) => (
                  <Link key={index} href={item.url} onClick={() => toggle()}>
                    <li>{item.label}</li>
                  </Link>
                ))}
              </ol>
            </nav>
            <div className={styles.buttons}>
              <Button
                label={t('logout')}
                onClick={logout}
                rounded={true}
                medium={true}
              />
            </div>
          </div>
        )}
        {!customer && (
          <div className={styles.buttons}>
            <Button
              label={t('login')}
              onClick={onClickLogin}
              rounded={true}
              medium={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default MobileHeader