import styles from './Button.module.scss'
import classNames from 'classnames'
import React, { ReactElement } from 'react'
import ArrowIcon from '@/assets/svg/arrow-right.svg'

type Props = {
  onClick?: () => void
  label: string,
  type: 'button' | 'submit' | 'reset',
  rounded?: boolean,
  dark?: boolean,
  darkGradient?: boolean,
  lightDark?: boolean,
  active?: boolean,
  arrow?: boolean,
  medium?: boolean,
  large?: boolean,
  icon?: React.FunctionComponent<any>,
  iconPosition?: 'left' | 'right',
  width?: string,
  className?: string,
  disabled?: boolean,
}

const Button = (props: Props) => {

  const onClickHandler = () => {
    if (props.onClick === undefined) return

    props.onClick()
  }

  const classes = classNames(
    styles.button,
    props.className,
    {
      [styles.rounded]: props.rounded,
      [styles.dark]: props.dark,
      [styles.darkGradient]: props.darkGradient,
      [styles.active]: props.active,
      [styles.medium]: props.medium,
      [styles.large]: props.large,
      [styles.lightDark]: props.lightDark,
      [styles.iconRight]: props.icon && props.iconPosition === 'right',
    })

  const IconComponent = props.icon as React.ElementType

  const style = props.width ? { width: props.width } : {}

  return (
    <button
      className={classes}
      onClick={onClickHandler}
      style={style}
      type={props.type}
      disabled={props.disabled}
    >
      {props.icon && (
        <IconComponent
          className={styles.icon}
        />
      )}
      {props.label}
      {props.arrow && (
        <ArrowIcon
          className={styles.arrow}
        />
      )}
    </button>
  )
}

Button.defaultProps = {
  type: 'button',
  rounded: false,
  dark: false,
  lightDark: false,
  active: false,
  arrow: false,
  medium: false,
  large: false,
  icon: null,
  iconPosition: 'left',
  width: null,
}

export default Button