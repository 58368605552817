import styles from './FloatingBar.module.scss'
import React from 'react'
import CartWidget from '@/components/cart/CartWidget/CartWidget'

type Props = {}

const FloatingBar = (props: Props) => {
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <CartWidget />
      </div>
    </div>
  )
}

export default FloatingBar