import Image from 'next/image'
import React from 'react'
import styles from './Footer.module.scss'
import logo from '@/assets/images/logo.png'
import useMenu from '@/utils/hooks/useMenu'
import classNames from 'classnames'
import Link from 'next/link'
import Facebook from '@/assets/svg/facebook.svg'
import Instagram from '@/assets/svg/instagram.svg'
import { useTranslation } from 'next-i18next'
import SiteCountryPicker from '@/features/app/SiteCountryPicker/SiteCountryPicker'
import sweden from '@/assets/images/flags/sweden.png'
import uk from '@/assets/images/flags/uk.png'
import LanguageModal from '@/components/common/LanguageModal/LanguageModal'
import useLocalizedPath from '@/utils/hooks/useLocalizedPath'


type Props = {}

const Footer = (props: Props) => {
  const menu = useMenu()
  const { t, i18n } = useTranslation(['footer', 'common'])
  const [showLanguageSelector, setShowLanguageSelector] = React.useState(false)
  const getLocalizedPath = useLocalizedPath()

  const toggleLanguageSelector = () => {
    setShowLanguageSelector(!showLanguageSelector)
  }

  return (
    <footer className={styles.container}>
      <div className="row">
        <div className={classNames('col-xs-12 col-md-3', styles.copyright)}>
          <div>
            <Image src={logo} width={224} alt="Statusfälgar" />
            <div>
              <div>
                Copyright © Statusfälgar<br />
                All rights reserved
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('col-xs-12 col-md', styles.navigation)}>
          <div>
            <div className={styles.navigationHeader}>{t('contact')}</div>
            <div className={styles.text}>
              <div>Arkitektvägen 4</div>
              <div>149 45 Nynäshamn</div>
              <div><a href="mailto:info@statusfalgar.se">info@statusfalgar.se</a></div>
              <div><a href="tel:+46852705264">+46 (0)8 527 052 64</a></div>
            </div>
          </div>
          <nav>
            <div className={styles.navigationHeader}>{t('content')}</div>
            <ol>
              {menu.map((item, index) => (
                <li key={index}>
                  <Link href={item.url}>{item.name}</Link>
                </li>
              ))}
            </ol>
          </nav>
          <nav>
            <div className={styles.navigationHeader}>{t('account')}</div>
            <ol>
              <li>
                <Link href={getLocalizedPath('login')}>{t('loginAndRegister')}</Link>
              </li>
            </ol>
          </nav>
          <nav>
            <div className={styles.navigationHeader}>{t('info')}</div>
            <ol>
              <li>
                <Link href={getLocalizedPath('info/policy')}>{t('gdpr')}</Link>
              </li>
              <li>
                <Link href={getLocalizedPath('info/terms')}>{t('terms')}</Link>
              </li>
              <li>
                <Link href={getLocalizedPath('info/shipping')}>{t('shippingAndReturns')}</Link>
              </li>
              <li>
                <Link href={getLocalizedPath('info/reseller')}>{t('reseller')}</Link>
              </li>
            </ol>
          </nav>
          <div>
            <div className={classNames(styles.navigationHeader)}>{t('social')}</div>
            <div className={styles.social}>
              <Link href="https://www.instagram.com/Statusfalgar.se/" className="social-icon" target="_blank">
                <Instagram />
              </Link>
              <Link href="https://www.facebook.com/statusfalgar/" className="social-icon" target="_blank">
                <Facebook />
              </Link>
            </div>
            <div className={classNames(styles.navigationHeader, "margin-top-1")}>{t('currencyAndRegion')}</div>
            <SiteCountryPicker
              countryAsLabel={true}
            />
            <div className={classNames(styles.navigationHeader, "margin-top-1")}>{t('common:language')}</div>
            <div onClick={toggleLanguageSelector}>
              {i18n.language === 'sv' ? <Image src={sweden} alt="Svenska" /> : <Image src={uk} alt="English" />}
            </div>
          </div>
        </div>
      </div>
      {showLanguageSelector && (
        <LanguageModal
          onClose={() => setShowLanguageSelector(false)}
        />
      )}
    </footer>
  )
}

export default Footer