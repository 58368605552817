/* eslint-disable @next/next/no-img-element */
import { DeviceType, useDeviceType } from '@/utils/hooks/useDeviceType'
import Head from 'next/head'
import React, { use, useEffect } from 'react'
import FloatingBar from '../FlaotingBar/FloatingBar'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MobileHeader from '../MobileHeader/MobileHeader'
import TopSiteBar from '../TopSiteBar/TopSiteBar'
import styles from './BaseLayout.module.scss'
import Script from 'next/script'
import { FB_PIXEL_ID } from '@/lib/fpixel'
import { ANALYTICS_ID } from '@/lib/ga'
import { GTM_ID } from '@/lib/gtm'
import useAnalyticsManager from '@/utils/hooks/useAnalyticsManager'

type Props = {
  children: React.ReactNode
}

const BaseLayout = ({ children }: Props) => {
  const device = useDeviceType()
  const analyticsManager = useAnalyticsManager()

  useEffect(() => {
    analyticsManager.pageView(window.location.pathname)
  }, [analyticsManager])

  return (
    <>
      <Head>
        <title>Statusfälgar</title>
        <meta name="description" content="Din ultimata destination för fälgar, däck och kompletta hjul" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google-site-verification" content="zug4UxVpwo4LAc9IitroKRo17QPSSNlQMTiMB-HOe8s" />
        <meta name="google-site-verification" content="oOlJaCHn0ce6YKX737ICedveAaeszIvKhkfpuH5eu14" />
        <link rel="icon" href="/favicon.ico" />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt="pixel"
            src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
          />
        </noscript>
      </Head>
      {device === DeviceType.Desktop && (
        <TopSiteBar />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          {device === DeviceType.Mobile && (
            <MobileHeader />
          )}
          {device === DeviceType.Desktop && (
            <Header />
          )}
          {children}
          <Footer />
          <FloatingBar />
        </div>
      </div>
      {/* <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${ANALYTICS_ID}');
        `}
      </Script> */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
            fbq('track', 'PageView');
          `,
        }}
      />
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          <!-- Google Tag Manager -->
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
          <!-- End Google Tag Manager -->
          `,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
    </>
  )
}

export default BaseLayout