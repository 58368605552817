import {
  CartAddWheelPackageModel,
  CartExpressCheckoutModel,
  CartModel,
  CartRowEditModel,
  CartRowOptionEditModel,
  OrderCreatedModel,
  SveaCheckoutResponseModel
} from '@/types/api'
import { baseApi } from './baseApi'

const cartApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCart: builder.query<CartModel, string>({
      query: (countryCode: string) => `Cart?countryCode=${countryCode}`,
      providesTags: ['Cart']
    }),
    getSveaCheckout: builder.query<SveaCheckoutResponseModel, number>({
      query: (payload) => `SveaCheckout/${payload}`,
      providesTags: ['Cart'],
      keepUnusedDataFor: 3600,
    }),
    prepareExpressCheckout: builder.mutation<OrderCreatedModel, CartExpressCheckoutModel>({
      query: (payload) => ({
        url: `CartExpressCheckout`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteCart: builder.mutation<CartModel, void>({
      query: () => ({
        url: `Cart`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cart'],
    }),
    addCartRow: builder.mutation<CartModel, CartRowEditModel>({
      query: (payload) => ({
        url: `CartRow`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Cart'],
    }),
    addWheelPackage: builder.mutation<void, CartAddWheelPackageModel>({
      query: (payload) => ({
        url: `CartWheelPackage`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Cart'],
    }),
    editCartRow: builder.mutation<CartModel, CartRowEditModel>({
      query: (payload) => ({
        url: `CartRow`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Cart'],
    }),
    editCartRowOption: builder.mutation<CartModel, CartRowOptionEditModel>({
      query: (payload) => ({
        url: `CartRowOption`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Cart'],
    }),
    deleteCartRow: builder.mutation<CartModel, number>({
      query: (payload) => ({
        url: `CartRow/${payload}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cart'],
    }),
  }),
})

export const {
  useGetCartQuery,
  useAddCartRowMutation,
  useAddWheelPackageMutation,
  useEditCartRowMutation,
  useEditCartRowOptionMutation,
  useDeleteCartMutation,
  useDeleteCartRowMutation,
  useGetSveaCheckoutQuery,
  usePrepareExpressCheckoutMutation,
  util: { getRunningQueriesThunk },
} = cartApi

export const { getSveaCheckout } = cartApi.endpoints