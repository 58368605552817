import styles from './LanguageModal.module.scss'
import React from 'react'
import Modal from '../Modal/Modal'
import sweden from '@/assets/images/flags/sweden.png'
import uk from '@/assets/images/flags/uk.png'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

type Props = {
  onClose: () => void
}

const LanguageModal = (props: Props) => {
  const { i18n } = useTranslation()
  const router = useRouter()

  const onClickHandler = (name: string) => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: name })
    props.onClose()
  }

  return (
    <div>
      <Modal
        title={''}
        onClose={props.onClose}
        clickOutsideToClose={true}
      >
        <div className={styles.component}>
          <div onClick={() => onClickHandler('sv')}>
            <Image src={sweden} alt="Svenska" /> Svenska
          </div>
          <div onClick={() => onClickHandler('en')}>
            <Image src={uk} alt="English" /> English
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default LanguageModal