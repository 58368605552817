import styles from './Modal.module.scss'
import React from 'react'
import CrossIcon from '@/assets/svg/cross.svg'
import { useClickOutside } from '@/utils/hooks/useClickOutside'
import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  onClose: () => void
  clickOutsideToClose?: boolean
  title: string,
  className?: string,
  scroll?: boolean
}

const Modal = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const onClickOutsideHandler = () => {
    if (!props.clickOutsideToClose)
      return

    props.onClose()
  }

  const onClickClose = () => {
    props.onClose()
  }

  useClickOutside(ref, onClickOutsideHandler)

  return (
    <div className={styles.component}>
      <div className={classNames(styles.container, props.className)} ref={ref}>
        <div className={styles.header}>
          {props.title && <div className={styles.title}>{props.title}</div>}
          <button className={styles.close} onClick={onClickClose}>
            <CrossIcon />
          </button>
        </div>
        <div className={classNames(styles.content, { [styles.scroll]: props.scroll })}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Modal