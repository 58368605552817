import { useState, useEffect } from 'react'

export enum DeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

/**
 * Listen to window resize event and return device type (mobile or desktop)
 * @param width - width to change device type, default is 768px
 * @returns 
 */
export const useDeviceType = (width?: number) => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.Desktop)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < (width || 970)) {
        setDeviceType(DeviceType.Mobile)
      } else {
        setDeviceType(DeviceType.Desktop)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return deviceType
}