import MiniPicker from '@/components/inputs/MiniPicker/MiniPicker'
import { useAppDispatch } from '@/utils/hooks/useAppDispatch'
import { useAppSelector } from '@/utils/hooks/useAppSelector'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useMemo } from 'react'
import { selectIncludeVat, setIncludeVat } from '../appSlice'
import { useGetCustomerQuery } from '@/api/customer'
import { usePrevious } from '@/utils/hooks/usePrevious'
import { useCountry } from '@/utils/hooks/useCountry'
import useTriggerEffect from '@/utils/hooks/useTriggerEffect'
import { useCustomer } from '@/utils/hooks/useCustomer'

type Props = {}

const VatPicker = (props: Props) => {
  const { t } = useTranslation('common')
  const includeVat = useAppSelector(selectIncludeVat)
  const dispatch = useAppDispatch()
  const { customer, isReseller } = useCustomer()
  const prevIsReseller = usePrevious(isReseller)
  const { country } = useCountry()
  const prevCountry = usePrevious(country)

  const includeVatValue = useMemo(() => {
    if (customer?.vatPercentage === 0) {
      return false
    }
    return includeVat
  }, [customer, includeVat])

  const options = [
    { name: t('includeVat'), value: true.toString() },
    { name: t('excludeVat'), value: false.toString() }
  ]

  const onChange = (value: string | number) => {
    dispatch(setIncludeVat(value === 'true'))
  }

  useEffect(() => {
    // If the customer is a reseller, we should always set the includeVat to false after login
    if (prevIsReseller !== undefined && isReseller && !prevIsReseller) {
      dispatch(setIncludeVat(false))

      // If not a reseller and the country does not include VAT, we should set the includeVat to false
    } else if (country && !country.includeVat && !isReseller) {
      dispatch(setIncludeVat(false))
    }
  }, [country, dispatch, isReseller, prevIsReseller])

  return (
    <div>
      <MiniPicker
        label={t('price')}
        name="vat"
        value={includeVatValue.toString()}
        options={options}
        onChange={onChange}
        disabled={customer?.vatPercentage === 0 || !country?.includeVat}
      />
    </div>
  )
}

export default VatPicker