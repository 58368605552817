import { useCallback, useEffect, useMemo } from "react"
import { useGetCountriesQuery } from "@/api/countries"
import { useAppDispatch } from "./useAppDispatch"
import { useAppSelector } from "./useAppSelector"
import { setCountry, setCountryChecked, setCurrency } from "@/features/app/appSlice"
import useLocalStorage from "./useLocalStorage"
import { useCustomer } from "./useCustomer"

export const useCountry = () => {
  const dispatch = useAppDispatch()
  const { data: countries } = useGetCountriesQuery()
  const { country: countryCode, currency, countryChecked } = useAppSelector(state => state.app)
  const [persistentCountry, setPersistentCountry] = useLocalStorage<string>('country')
  const { customer } = useCustomer()

  const changeCountry = useCallback(async (countryCode: string, currencyCode: string | null) => {
    const country = countries?.find((c) => c.countryCode === countryCode)

    if (country) {
      setPersistentCountry(country.countryCode)
      dispatch(setCountry(country.countryCode))
      if (!currencyCode)
        dispatch(setCurrency(country.currencyCode))
      else
        dispatch(setCurrency(currencyCode))
    } else {
      console.error('Country not found:', countryCode)
    }
  }, [countries, dispatch, setPersistentCountry])

  const setCountryFromGeoInfo = useCallback(async () => {
    try {
      dispatch(setCountryChecked(true))
      const response = await fetch('/api/geolocation')
      const data = await response.json()

      if (!data.country) return

      changeCountry(data.country, null)
    } catch (error) {
      console.error('Failed to fetch geo info:', error)
      dispatch(setCountryChecked(false))
    }
  }, [changeCountry, dispatch])

  // If the country is not set in local storage, and we haven't checked the country yet, check it
  useEffect(() => {
    if (!persistentCountry && !countryChecked && countries && !customer) {
      setCountryFromGeoInfo()
    }
  }, [persistentCountry, countryChecked, countries, setCountryFromGeoInfo, customer])

  // If the country is set in local storage, but not in the state, set it
  useEffect(() => {
    if (!customer && persistentCountry && persistentCountry !== countryCode && countries) {
      changeCountry(persistentCountry, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  // User login
  useEffect(() => {
    const currencyMissMatch = customer?.currencyCode && customer?.currencyCode !== currency
    const countryMissMatch = customer?.countryCode && customer?.countryCode !== countryCode

    if (customer && (countryMissMatch || currencyMissMatch)) {
      changeCountry(customer.countryCode, customer.currencyCode)
    }
  }, [customer, countryCode, changeCountry, currency])

  const country = countries?.find((c) => c.countryCode === countryCode)

  return {
    countries,
    country,
    countryCode,
    currency,
    changeCountry
  }
}