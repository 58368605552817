import { useTranslation } from 'next-i18next'
import React from 'react'
import useLocalizedPath from './useLocalizedPath'

interface Menu {
  url: string,
  name: string,
}

const useMenu = () => {
  const [menu, setMenu] = React.useState<Menu[]>([])
  const { t } = useTranslation('common')
  const getLocalizedPath = useLocalizedPath()

  React.useEffect(() => {
    setMenu([
      { url: getLocalizedPath('wheels'), name: t('menu.wheels') },
      { url: getLocalizedPath('rims'), name: t('menu.rims') },
      { url: getLocalizedPath('tyres'), name: t('menu.tyres') },
      { url: getLocalizedPath('accessories'), name: t('menu.accessories') },
      { url: getLocalizedPath('about'), name: t('menu.about') },
      { url: getLocalizedPath('contact'), name: t('menu.contact') }
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return menu
}

export default useMenu

