import Image from 'next/image'
import React, { useRef } from 'react'
import styles from './LanguagePicker.module.scss'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useClickOutside } from '@/utils/hooks/useClickOutside'

type Props = {}

type LanguageItemProps = {
  languageCode: string,
  text: string,
  image: any,
  onClick: (name: string) => void
}

const LanguageItem = (props: LanguageItemProps) => {
  const onClickHandler = () => {
    props.onClick(props.languageCode)
  }

  return (
    <div onClick={onClickHandler}>
      <Image src={`/countries/${props.image}.png`} alt={props.text} width="20" height="14" /> {props.text}
    </div>
  )
}

const LanguagePicker = (props: Props) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const router = useRouter()
  
  useClickOutside(dropdownRef, () => setIsOpen(false))

  const onClickHandler = (name: string) => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: name })
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const selectedLanguage = router.locale === 'sv' ? 'se' : 'gb'
  const selectedLanguageText = router.locale === 'sv' ? 'Svenska' : 'English'

  return (
    <div ref={dropdownRef} className={styles.component} onClick={toggle}>
      <div className={styles.current}>
        <div>{t('language')}</div> <Image src={`/countries/${selectedLanguage}.png`} alt={selectedLanguageText} width="20" height="14" />
      </div>

      <div className={classNames(styles.dropdown, { [styles.isOpen]: isOpen })}>
        <LanguageItem languageCode="sv" text="Svenska" image={'se'} onClick={onClickHandler} />
        <LanguageItem languageCode="en" text="English" image={'gb'} onClick={onClickHandler} />
      </div>
    </div>
  )
}

export default LanguagePicker