import NameValue from '@/types/NameValue'
import { useClickOutside } from '@/utils/hooks/useClickOutside'
import classNames from 'classnames'
import React, { useRef } from 'react'
import styles from './MiniPicker.module.scss'

type Props = {
  label: string,
  name: string,
  value: string,
  options?: NameValue[],
  disabled?: boolean,
  onChange?: (value: string | number, name: string) => void
}

type ItemProps = {
  item: NameValue
  onClick: (value: string | number, name: string) => void
}

const MinPickerItem = (props: ItemProps) => {
  const onClickHandler = () => {
    props.onClick(props.item.value, props.item.name)
  }

  return (
    <div
      className={styles.item}
      onClick={onClickHandler}
    >
      {props.item.name}
    </div>
  )
}

const MiniPicker = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(dropdownRef, () => setIsOpen(false))

  const onChangeHandler = (value: string | number, name: string) => {
    if (props.onChange === undefined) return

    props.onChange(value, name)
  }

  const toggle = () => {
    if (props.disabled) return
    setIsOpen(!isOpen)
  }

  const selected = props.options?.find(option => option.value === props.value)

  return (
    <div
      className={classNames(styles.component, { [styles.disabled]: props.disabled })}
      ref={dropdownRef}
      onClick={toggle}
    >
      <div className={styles.current}>
        {props.label} {selected?.name} 
      </div>
      <div className={classNames(styles.dropdown, { [styles.isOpen]: isOpen })}>
        {props.options?.map((option, index) => (
          <MinPickerItem
            key={index}
            item={option}
            onClick={onChangeHandler}
          />
        ))}
      </div>
    </div>
  )
}

export default MiniPicker