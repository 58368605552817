import styles from './Account.module.scss'
import { useGetCustomerQuery } from '@/api/customer'
import { useTranslation } from 'next-i18next'
import React from 'react'
import AccountIcon from '@/assets/svg/account.svg'

type Props = {}

const Account = (props: Props) => {
  const { t } = useTranslation('common')
  const { data: customer } = useGetCustomerQuery()

  return (
    <div className={styles.component}>
      <div className={styles.account}>
        <div className={styles.label}>{t('menu.myProfile')}</div>
        <div className={styles.name}>{customer?.name}</div>
      </div>
      <div className={styles.icon}>
        <AccountIcon />
      </div>
    </div>
  )
}

export default Account