import styles from './Header.module.scss'
import React from 'react'
import useMenu from '@/utils/hooks/useMenu'
import Link from 'next/link'
import logo from '@/assets/images/logo.png'
import Image from 'next/image'
import { useGetCustomerQuery } from '@/api/customer'
import { useTranslation } from 'next-i18next'
import Account from '@/components/common/Account/Account'
import useLogout from '@/utils/hooks/useLogout'
import CartWidget from '@/components/cart/CartWidget/CartWidget'
import useLocalizedPath from '@/utils/hooks/useLocalizedPath'

type Props = {}

const Header = (props: Props) => {
  const menuItems = useMenu()
  const { data: customer } = useGetCustomerQuery()
  const { t } = useTranslation('common')
  const logout = useLogout()
  const getLocalizedPath = useLocalizedPath()

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <header>
          <Link href="/"><Image src={logo} width={264} alt="Statusfälgar" /></Link>
        </header>
        <nav>
          <ol>
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link href={item.url}>{item.name}</Link>
              </li>
            ))}
            {customer && customer.id ? (
              <li>
                <Link href="/account">
                  <Account />
                </Link>
              </li>
            ) : (
              <li>
                <Link href={getLocalizedPath('login')}>{t('menu.login')}</Link>
              </li>
            )}
            {customer && customer.id && (
              <li onClick={logout}>
                {t('common:logout')}
              </li>
            )}
            <CartWidget />
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default Header