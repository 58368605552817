import styles from './TopSiteBar.module.scss'
import React from 'react'
import CurrencySelector from '@/features/app/CurrencySelector/CurrencySelector'
import LanguagePicker from '@/features/app/LanguagePicker/LanguagePicker'
import VatPicker from '@/features/app/VatPicker/VatPicker'
import PhoneIcon from '@/assets/svg/phone.svg'
import SiteCountryPicker from '@/features/app/SiteCountryPicker/SiteCountryPicker'
import { useTranslation } from 'next-i18next'

type Props = {}

const TopSiteBar = (props: Props) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.phone}>
          <PhoneIcon className={styles.icon} />
          <a href="tel:+46852705264">+46 (0)8 527 052 64</a>
        </div>
        <SiteCountryPicker
          label={t('common:country')}
        />
        <LanguagePicker />
        {/* <div className="row">
          <CurrencySelector />
        </div> */}
        <div className="row">
          <VatPicker />
        </div>
      </div>
    </div>
  )
}

export default TopSiteBar